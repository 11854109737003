@import '~antd/dist/antd.css';

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  user-select: none;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  width: 206px;
  height: 40px;
  margin: 8px 20px;
  background: url(../assets/svg/Logo.svg) left center no-repeat;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-calendar-customize-header-wrapper {
	width: 100%;
	border: 1px solid #f0f0f0;
	border-radius: 2px;
}

.ant-picker-content th {
  padding: 8px 0;
  font-weight: bold;
	border-bottom: 1px solid #f0f0f0;
}

.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.site-layout-background {
  background: #fff;
}

.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
